var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiListItem from '@cros/shared/components/misc/RdiListItem';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { TaskPriorityEnum } from '@cros/shared/constants/enums/TaskPriorityEnum';
import { TaskTypeEnum } from '@cros/shared/constants/enums/TaskTypeEnum';
import { formatDatetimeLong } from '@cros/shared/utils/dateUtils';
import { CircularProgress, List, ListItemText } from '@material-ui/core';
import { getMessagingThreadRoute } from '~/components/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { MessagingStore } from '~/stores/MessagingStore';
var MessagingPage = function (_a) {
    var accountStore = _a.accountStore;
    var messagingStore = new MessagingStore(accountStore);
    var threadsEndRef = useRef(null);
    var _b = useState([]), threads = _b[0], setThreads = _b[1];
    var _c = useState(false), isFormDisplayed = _c[0], setIsFormDisplayed = _c[1];
    var _d = useState(false), loading = _d[0], setIsLoading = _d[1];
    var _e = useState(''), title = _e[0], setTitle = _e[1];
    var fetchPatientMessagingThreads = function () { return __awaiter(void 0, void 0, void 0, function () {
        var patientThreads;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, messagingStore.listThreads()];
                case 1:
                    patientThreads = _a.sent();
                    setIsLoading(true);
                    setThreads(patientThreads);
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (event) {
        setTitle(event.value);
    };
    var handleNewTaskCreate = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setIsLoading(true);
                    return [4 /*yield*/, messagingStore.createTask({
                            title: title,
                            priority: TaskPriorityEnum.MEDIUM,
                            type: TaskTypeEnum.TASK
                        })];
                case 1:
                    _a.sent();
                    fetchPatientMessagingThreads();
                    setIsLoading(false);
                    setIsFormDisplayed(false);
                    setTitle('');
                    return [2 /*return*/];
            }
        });
    }); };
    var scrollToBottom = function () {
        setTimeout(function () { var _a; return (_a = threadsEndRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' }); }, 0);
    };
    var handleCreateThreadClick = function () {
        setIsFormDisplayed(!isFormDisplayed);
        scrollToBottom();
    };
    useEffect(function () {
        fetchPatientMessagingThreads();
    }, []);
    return (React.createElement(FixedWidthContent, { style: { maxWidth: '780px' }, white: true },
        React.createElement(RdiTypography, { variant: "h1", style: { color: TypographyColorsEnum.Primary } }, "Messaging"),
        React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.Black, style: { marginBottom: '20px' } }, "Send message to the clinic."),
        React.createElement(RdiLine, null),
        React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.Black, style: { marginBottom: '20px' } }, "Threads"),
        React.createElement(RdiLink, { xs: true, onClick: handleCreateThreadClick, style: { marginTop: '15px', marginBottom: '15px' } }, isFormDisplayed ? 'Close' : 'Create new message thread'),
        isFormDisplayed && (React.createElement("form", { onSubmit: handleNewTaskCreate },
            React.createElement(RdiInput, { label: "Message Title", name: "message", value: title, onChange: handleChange, placeholder: "Message Title" }),
            React.createElement("div", null,
                React.createElement(RdiButton, { submit: true, loading: loading, style: { width: '164px' } }, "Create thread")))),
        React.createElement("div", { ref: threadsEndRef }),
        loading && React.createElement(CircularProgress, null),
        React.createElement(List, { style: { maxHeight: 'calc(100vh - 300px)', overflow: 'auto' } }, threads.map(function (thread) { return (React.createElement(RdiListItem, { button: true, component: NavLink, to: "" + getMessagingThreadRoute(thread.id) },
            React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2', style: { textTransform: 'uppercase' } }, secondary: formatDatetimeLong(thread.createdAt) },
                thread.title,
                " (",
                thread.siteId,
                ")"))); }))));
};
export default injectAccountStore(observer(MessagingPage));
