var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiJsxParser from '@cros/shared/components/layout/RdiJsxParser';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { Note, NoteTypeEnum } from '@cros/shared/types/models/Note';
import { formatDatetimeLong } from '@cros/shared/utils/dateUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { CircularProgress, createStyles, Tooltip, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import RdiFileDescription from '@cros/shared/components/misc/RdiFileDescription';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { PatientRoutes } from '~/components/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { MessagingStore } from '~/stores/MessagingStore';
import config from '~/config';
import UploadedFile from '@cros/shared/types/models/UploadedFile';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { utilValidate } from '@cros/shared/utils/validationUtils';
var styles = function (theme) {
    var _a;
    return createStyles({
        textBubble: {
            padding: '0.5em 0.75em',
            borderRadius: '1em',
            boxShadow: '0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);',
            maxWidth: '500px'
        },
        backLink: {
            fontFamily: 'Montserrat',
            margin: '0px 0px 20px -5px'
        },
        fileInput: (_a = {},
            _a[theme.breakpoints.up('md')] = {
                flexDirection: 'row !important'
            },
            _a[theme.breakpoints.down('md')] = {
                flexDirection: 'column'
            },
            _a)
    });
};
var ThreadPage = /** @class */ (function (_super) {
    __extends(ThreadPage, _super);
    function ThreadPage(props) {
        var _this = _super.call(this, props) || this;
        _this.saving = false;
        _this.messages = [];
        _this.loading = true;
        _this.data = {
            message: {
                value: '',
                error: null,
                rule: 'string|required|min:2',
                mentions: []
            },
            files: {
                value: null,
                error: null
            }
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors, true);
        };
        _this.addNote = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var hasErrors, newNote, note, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        e.preventDefault();
                        hasErrors = this.validateForm();
                        if (hasErrors) {
                            return [2 /*return*/];
                        }
                        note = new Note({
                            note: this.data.message.value,
                            taskId: this.threadId,
                            type: NoteTypeEnum.Message
                        });
                        this.saving = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 4, 5]);
                        return [4 /*yield*/, this.messagingStore.addMessage(this.threadId, note, this.data.files.value)];
                    case 2:
                        newNote = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.messagingStore.listThreadMessages(this.threadId)];
                    case 3:
                        _a.messages = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.saving = false;
                        this.data.message.value = '';
                        this.data.files.value = [];
                        if (!newNote) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.uploadFile = function (event) {
            var f = event.files && event.files.length ? event.files[0] : undefined;
            if (!_this.data.files.value) {
                _this.data.files.value = [];
            }
            if (f.size > 104857600) {
                // 100mb
                _this.data.files.error = 'File to larg (100mb max)';
                return;
            }
            _this.data.files.value.push(new UploadedFile({
                originalname: f.name,
                mimetype: f.type,
                size: f.size,
                url: null,
                file: f
            }));
        };
        _this.removeFile = function (item) {
            _this.data.files.value.find(function (x) { return x.originalname === item.originalname; }).deleted = true;
        };
        _this.messagingStore = new MessagingStore(props.accountStore);
        return _this;
    }
    Object.defineProperty(ThreadPage.prototype, "threadId", {
        get: function () {
            var params = this.props.match.params;
            return params.id;
        },
        enumerable: false,
        configurable: true
    });
    ThreadPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.messagingStore.listThreadMessages(this.threadId)];
                    case 1:
                        _a.messages = _b.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ThreadPage.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var _d = this.props, accountDetail = _d.accountStore.accountDetail, classes = _d.classes;
        return (React.createElement(FixedWidthContent, { style: { maxWidth: '780px' }, white: true },
            React.createElement("div", { style: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement("div", null,
                    React.createElement(RdiLink, { to: PatientRoutes.MESSAGING_ROUTE, className: classes.backLink, startIcon: React.createElement(KeyboardArrowLeft, null) }, "Back to messaging"),
                    React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h6" }, "Messages")),
                !this.loading && (React.createElement("div", { style: { height: '100%', paddingRight: '20px' } },
                    React.createElement(RdiLine, null),
                    React.createElement("div", { className: "notesTable", style: { height: 'calc(100vh - 440px)', overflowY: 'auto' } }, (_a = this.messages) === null || _a === void 0 ? void 0 : _a.map(function (note, index) {
                        var _a, _b;
                        return (React.createElement("div", { style: {
                                width: '100%',
                                marginBottom: '8px',
                                padding: '8px',
                                display: 'flex',
                                justifyContent: note.ownerId === accountDetail.id ? 'flex-end' : 'flex-start'
                            }, key: index },
                            React.createElement("div", null,
                                React.createElement("div", null,
                                    React.createElement("b", { style: { fontSize: '11px', margin: '0 0 4px 4px' } },
                                        note.type === NoteTypeEnum.Note && (React.createElement(Tooltip, { title: "Note" },
                                            React.createElement(InfoIcon, { color: "primary", style: {
                                                    fontSize: '16px',
                                                    verticalAlign: 'bottom',
                                                    marginBottom: '2px',
                                                    marginRight: '4px'
                                                } }))),
                                        note.owner ? note.owner.fullName : '')),
                                React.createElement("div", { className: classes.textBubble }, note.isSystemGenerated ? (React.createElement(RdiJsxParser, null, note.note)) : (React.createElement(RdiInput, { disabled: true, borderWidth: "0px", value: note.note, type: "mentions", style: {
                                        padding: '0px',
                                        marginBottom: '0px'
                                    } }))),
                                React.createElement("div", { style: { fontSize: '11px', fontStyle: 'italic' } }, formatDatetimeLong(note.createdAt)),
                                ((_a = note.attachments) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement("div", null, (_b = note.attachments) === null || _b === void 0 ? void 0 : _b.map(function (attachment, fileIndex) { return (React.createElement(RdiFileDescription, { config: config, key: fileIndex, file: attachment, style: { fontSize: '12px' } })); }))) : null)));
                    })),
                    React.createElement("form", { onSubmit: this.addNote },
                        React.createElement(FlexContainer, { style: { marginTop: '10px' }, margin: 15 },
                            React.createElement(RdiInput, { label: "Add a new message", name: "message", value: this.data.message.value, type: "mentions", style: { minHeight: '90px', marginTop: '4px' }, errorText: this.data.message.error, onChange: function (e) { return _this.fieldChange(e, 'message'); }, placeholder: "Add a message..." })),
                        React.createElement(FlexContainer, { alignItems: "flex-start" },
                            React.createElement(RdiButton, { submit: true, loading: this.saving, style: { width: '200px' } }, "Add message"),
                            React.createElement(FlexContainer, { flexDirection: "column", className: classes.fileInput },
                                React.createElement(RdiInput, { type: "file", label: "Add attachment", name: "file", value: null, inputProps: { accept: 'image/*,application/pdf,.xls*,.doc*,.txt' }, style: {
                                        flexShrink: 4,
                                        marginLeft: '24px',
                                        marginRight: '16px',
                                        marginBottom: 0
                                    }, errorText: this.data.files.error, onChange: this.uploadFile, disabled: (_c = (_b = this.data.files.value) === null || _b === void 0 ? void 0 : _b.filter(function (x) { return !x.deleted; })) === null || _c === void 0 ? void 0 : _c.length }),
                                this.data.files.value &&
                                    this.data.files.value
                                        .filter(function (x) { return !x.deleted; })
                                        .map(function (item, index) { return (React.createElement(RdiFileDescription, { config: config, key: index, file: item, onRemove: function () { return _this.removeFile(item); } })); }))))))),
            this.loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null)))));
    };
    __decorate([
        observable
    ], ThreadPage.prototype, "saving", void 0);
    __decorate([
        observable
    ], ThreadPage.prototype, "messages", void 0);
    __decorate([
        observable
    ], ThreadPage.prototype, "loading", void 0);
    __decorate([
        observable
    ], ThreadPage.prototype, "data", void 0);
    __decorate([
        computed
    ], ThreadPage.prototype, "threadId", null);
    ThreadPage = __decorate([
        observer
    ], ThreadPage);
    return ThreadPage;
}(Component));
export default injectAccountStore(observer(withStyles(styles)(withRouter(ThreadPage))));
