var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from "mobx";
import { TaskStatusEnum } from "s~/constants/enums/TaskStatusEnum";
import { BillingCode } from "s~/types/models/BillingCode";
import { DeviceData } from "s~/types/models/DeviceData";
import { ICDCode } from "s~/types/models/ICDCode";
import { Model } from "s~/types/models/Model";
import { Patient } from "s~/types/models/Patient";
import { Trial } from "s~/types/models/Trial";
import { User } from "s~/types/models/User";
import { getDateFromString } from "s~/utils/dateUtils";
import { Enrollment } from "./Enrollment";
var Task = /** @class */ (function (_super) {
    __extends(Task, _super);
    function Task(n) {
        var _this = _super.call(this, n) || this;
        _this.id = n.id;
        _this.title = n.title;
        _this.billingName = n.billingName;
        _this.priority = n.priority;
        _this.status = n.status;
        _this.enrollmentId = n.enrollmentId;
        _this.enrollment = n.enrollment ? new Enrollment(n.enrollment) : undefined;
        _this.trialId = n.trialId;
        _this.trial = n.trial ? new Trial(n.trial) : undefined;
        _this.patientId = n.patientId;
        _this.patient = n.patient ? new Patient(n.patient) : undefined;
        _this.assigneeId = n.assigneeId;
        _this.assignee = n.assignee ? new User(n.assignee) : undefined;
        _this.resolvedById = n.resolvedById;
        _this.resolvedBy = n.resolvedBy ? new User(n.resolvedBy) : undefined;
        _this.type = n.type;
        _this.onResolveNote = n.onResolveNote;
        _this.note = n.note;
        _this.resolvedAt = n.resolvedAt
            ? getDateFromString(n.resolvedAt)
            : undefined;
        _this.createdAt = n.createdAt ? getDateFromString(n.createdAt) : undefined;
        _this.deviceDatapointId = n.deviceDatapointId;
        _this.deviceDatapoint = n.deviceDatapoint
            ? new DeviceData(n.deviceDatapoint)
            : undefined;
        _this.unreadNotesCount = n.unreadNotesCount;
        _this.billingCodes = n.billingCodes
            ? n.billingCodes.map(function (b) { return new BillingCode(b); })
            : [];
        _this.icdCodes = n.icdCodes ? n.icdCodes.map(function (b) { return new ICDCode(b); }) : [];
        _this.billingNote = n.billingNote;
        _this.billingType = n.billingType;
        _this.siteId = n.siteId;
        return _this;
    }
    Object.defineProperty(Task.prototype, "generatedTitle", {
        get: function () {
            var _a;
            return ((_a = this.deviceDatapoint) === null || _a === void 0 ? void 0 : _a.categoryLabel) || this.title;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Task.prototype, "isResolved", {
        get: function () {
            return this.status === TaskStatusEnum.RESOLVED;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        computed
    ], Task.prototype, "generatedTitle", null);
    __decorate([
        observable
    ], Task.prototype, "priority", void 0);
    __decorate([
        observable
    ], Task.prototype, "status", void 0);
    __decorate([
        observable
    ], Task.prototype, "assignee", void 0);
    __decorate([
        observable
    ], Task.prototype, "assigneeId", void 0);
    __decorate([
        observable
    ], Task.prototype, "resolvedBy", void 0);
    __decorate([
        observable
    ], Task.prototype, "resolvedById", void 0);
    __decorate([
        observable
    ], Task.prototype, "resolvedAt", void 0);
    __decorate([
        observable
    ], Task.prototype, "billingCodes", void 0);
    __decorate([
        observable
    ], Task.prototype, "icdCodes", void 0);
    __decorate([
        computed
    ], Task.prototype, "isResolved", null);
    return Task;
}(Model));
export { Task };
