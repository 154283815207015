var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from "mobx";
import { Device } from "s~/types/models/Device";
import { Model } from "s~/types/models/Model";
import { Payment } from "s~/types/models/Payment";
import { Trial } from "s~/types/models/Trial";
import { User } from "s~/types/models/User";
import { getDateFromString, getDaysAgo } from "s~/utils/dateUtils";
import { Patient } from "./Patient";
import { Visit } from "./Visit";
var Enrollment = /** @class */ (function (_super) {
    __extends(Enrollment, _super);
    function Enrollment(p) {
        var _this = _super.call(this, p) || this;
        _this.getDeviceById = function (deviceId) {
            var _a;
            var device = (_a = _this.devices) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.id === deviceId; });
            return device;
        };
        _this.id = p.id;
        _this.status = p.status;
        _this.invitationDate = p.invitationDate
            ? getDateFromString(p.invitationDate)
            : null;
        _this.progress = p.progress ? parseFloat(p.progress) : 0;
        _this.code = p.code;
        _this.nextVisit = p.nextVisit ? new Visit(p.nextVisit) : null;
        _this.feedbackAverageRating = p.feedbackAverageRating
            ? parseFloat(p.feedbackAverageRating).toFixed(1)
            : null;
        if (p.feedbackMostRecentDate) {
            _this.feedbackMostRecentDate = getDateFromString(p.feedbackMostRecentDate);
        }
        if (p.trialFinishedAt) {
            _this.trialFinishedAt = getDateFromString(p.trialFinishedAt);
        }
        _this.patientId = p.patientId;
        _this.patient = p.patient ? new Patient(p.patient) : null;
        _this.devices = p.devices ? p.devices.map(function (d) { return new Device(d); }) : [];
        _this.payments = p.payments ? p.payments.map(function (x) { return new Payment(x); }) : [];
        _this.trial = p.trial ? new Trial(p.trial) : null;
        _this.trialId = p.trialId;
        _this.numVisits = p.numVisits;
        _this.siteId = p.siteId;
        _this.providerId = p.providerId;
        _this.provider = p.provider ? new User(p.provider) : null;
        _this.historyDeviceTypes = p.historyDeviceTypes
            ? p.historyDeviceTypes.map(function (d) { return new Device(d); })
            : [];
        return _this;
    }
    Object.defineProperty(Enrollment.prototype, "wearableLastUpdatedString", {
        get: function () {
            if (!this.latestDeviceData) {
                return "";
            }
            var wearableDaysAgo = getDaysAgo(this.latestDeviceData.timestamp);
            if (wearableDaysAgo === 0)
                return "Last updated today";
            return "Last updated " + wearableDaysAgo + " days ago";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Enrollment.prototype, "latestDeviceData", {
        get: function () {
            var latestDP = null;
            for (var _i = 0, _a = this.devices; _i < _a.length; _i++) {
                var device = _a[_i];
                if (device === null || device === void 0 ? void 0 : device.latestDatapoint) {
                    if (latestDP && device.latestDatapoint.timestamp > latestDP.timestamp) {
                        latestDP = device.latestDatapoint;
                    }
                    if (!latestDP) {
                        latestDP = device.latestDatapoint;
                    }
                }
            }
            return latestDP;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Enrollment.prototype, "feedbackLastUpdatedString", {
        get: function () {
            if (!this.feedbackMostRecentDate) {
                return "";
            }
            var feedbackDaysAgo = getDaysAgo(this.feedbackMostRecentDate);
            if (feedbackDaysAgo === 0)
                return "Last updated today";
            return "Last updated " + feedbackDaysAgo + " days ago";
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        computed
    ], Enrollment.prototype, "wearableLastUpdatedString", null);
    __decorate([
        computed
    ], Enrollment.prototype, "latestDeviceData", null);
    __decorate([
        computed
    ], Enrollment.prototype, "feedbackLastUpdatedString", null);
    __decorate([
        observable
    ], Enrollment.prototype, "providerId", void 0);
    __decorate([
        observable
    ], Enrollment.prototype, "provider", void 0);
    return Enrollment;
}(Model));
export { Enrollment };
