import CenteredContent from 's~/components/layout/content/CenteredContent';
import PublicPageWrapper from 's~/components/layout/PublicPageWrapper';
import RdiTypography from 's~/components/layout/RdiTypography';
import React from 'react';
var PrivacyPage = function () {
    return (React.createElement(PublicPageWrapper, null,
        React.createElement(CenteredContent, { style: {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '800px',
                margin: '0px auto',
                padding: '35px 85px',
                background: 'rgba(250,250,250,0.12)',
                border: '1px solid',
                borderColor: 'rgba(0,0,0,0.12)',
            } },
            React.createElement(RdiTypography, { style: { marginBottom: '36px', fontSize: '32px', fontWeight: 'bold' }, variant: "h1" }, "PRIVACY POLICY"),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', width: '100%', textAlign: 'left' } }, "1. Introduction"),
            React.createElement(RdiTypography, { variant: "body1" }, "MediSci Venture website and Medisci CROS app (\u201CMediSci\u201D, \u201Capp\u201D, \u201Cwe\u201D, \u201Cour\u201D or \u201Cus\u201D) has created this Privacy Policy to explain the way we collect, use, protect, share, discloser and process your personal information while operating our website."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "2. Information We Collect"),
            React.createElement(RdiTypography, { variant: "body1" }, "To perform our service, we may collect your information either directly or automated such as Personal Information, Log Files."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "2.1. Personal Information"),
            React.createElement(RdiTypography, { variant: "body1" },
                "Firstly, your data is stored when you communicate with us. This may, for example, be data that you enter in our chat on the site. Other data is collected automatically when visiting the website through our IT systems. These are above all technical data (e.g., Internet browser, operating system or time of the page request). The collection of this data is automatic as soon as you visit our platform.",
                React.createElement("ul", null,
                    React.createElement("li", null, "Registration Information: When you register for \"MediSci\" (for our free offer, payment, paid subscription, or use of a code), we collect the personal information that you voluntarily provide to us when registering through your access device, except for your passwords."),
                    React.createElement("li", null, "Mobile Advertising ID: If not disabled by the user, we collect the Mobile Advertising ID provided by your mobile device. If you do not want us to collect this Mobile Advertising ID, you can always disable or change this on your device. Please see this manual for iOS devices and this manual for Android devices."),
                    React.createElement("li", null, "Cookies: For the provision of the MediSci service and to make our offer more user-friendly, effective and secure, we also use cookies for data collection and storage. Cookies are small data packets that are stored on your device and do no harm."),
                    React.createElement("li", null, "Tracking Services: We use tracking services to collect information about the behavior of visitors to our pages. Therefore, we can ensure an optimal user experience. These services use pseudonymous user profiles with the help of cookies, so that a direct inference to persons is not possible. You have the contradict the creation of these profiles at any time. Incidentally, each access device can be configured in such a way that basically no cookies can be created at all, or they can be deleted directly after the session."),
                    React.createElement("li", null, "Newsletter: When you sign up for our newsletter, your email address will be used for information on advertising purposes. You can unsubscribe at any time."),
                    React.createElement("li", null, "Apple \u201CHealth\u201D App Data: Everything you input into your \u201CHealth\u201D app through your Apple Watch or other IOT medical devices including but not limited to your name, emoji, Apple Watch or other IOT devices type, settings and other information are automatically stored on our server."))),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "3. How we collect your data"),
            React.createElement(RdiTypography, { variant: "body1" },
                "We collect your data in three different ways:",
                React.createElement("ul", null,
                    React.createElement("li", null, "We collect your data automatically when you visit our website or use our mobile app through Apple \u201CHealth\u201D app."),
                    React.createElement("li", null, "We also collect your data through your voluntary input on our website or app or other IOT devices or Apple watch\u2019s voice assistance such as Amazon Alexa or Google Assistant or Apple Siri."),
                    React.createElement("li", null, "We collect your data through your voluntary input on our website or app. "))),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "4. Information Security/Shield"),
            React.createElement(RdiTypography, { variant: "body1" }, "We take all necessary steps to make your data encrypted and protected."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "5. Payment information Security/Shield"),
            React.createElement(RdiTypography, { variant: "body1" }, "A direct payment gateway to complete your purchase. It is coded through the Payment Card Industry Data Security Standard (PCI-DSS). Your purchase transaction data is stored only as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is then deleted."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "6. Why We need Your Information and How We may Use Your Personal Information"),
            React.createElement(RdiTypography, { variant: "body1" },
                "MediSci or MediSci CROS depends on a number of grounds to collect, use, and share your information. We collect, use and share your information:",
                React.createElement("ul", null,
                    React.createElement("li", null, "to provide you with just in time health services recommended by your doctors and uses;"),
                    React.createElement("li", null, "to remind you medical appointment;"),
                    React.createElement("li", null, "to share your medical records;"),
                    React.createElement("li", null, "to make a payment system for clinical trials, participation and wellness goals;"),
                    React.createElement("li", null, "to provide, operate, and maintain our website;"),
                    React.createElement("li", null, "to improve, personalize, and expand our website;"),
                    React.createElement("li", null, "to understand and analyze how you use our website;"),
                    React.createElement("li", null, "to develop and protect new products, services, features, and functionality"),
                    React.createElement("li", null, "to settle disputes;"),
                    React.createElement("li", null, "to provide customer support;"),
                    React.createElement("li", null,
                        "when you have provided your affirmative consent, which you may revoke at any time, such as by signing up for my mailing list for receiving promotional mails regarding the website.",
                        ' '),
                    React.createElement("li", null, "to comply with a legal obligation; "),
                    React.createElement("li", null, "court order or in connection with a legal claim; such as keeping purchasing information if required by tax law; "))),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "7. Sharing and Disclosure of Your Information"),
            React.createElement(RdiTypography, { variant: "body1" },
                "To us information your information is very important. We do not sell, lend or share any of your information to others except Legal Binding: We may collect, use, retain, and share your information if We have a good faith belief that it is reasonably necessary to:",
                React.createElement("ol", null,
                    React.createElement("li", null, "respond to legal process or to government requests; "),
                    React.createElement("li", null, "enforce our agreements, terms and policies;"),
                    React.createElement("li", null, "prevent, investigate, and address fraud and other illegal activity, security, or technical issues; "),
                    React.createElement("li", null, "protect the rights, property, and safety of our users, or others."))),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "8. Do Not Track Disclosure"),
            React.createElement(RdiTypography, { variant: "body1" }, "Please be noted that we do not alter our site\u2019s data collection and use practices when we see a do not track (DNT) signal from your browser."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "9. CCPA Privacy Rights (Do Not Sell My Personal Information):"),
            React.createElement(RdiTypography, { variant: "body1" },
                "Under the CCPA, among other rights, California residences have the right to:",
                React.createElement("ul", null,
                    React.createElement("li", null, "Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers."),
                    React.createElement("li", null, "Request that a business delete any personal data about the consumer that a business has collected."),
                    React.createElement("li", null, "Request that a business that sells a consumer's personal data, not sell the consumer's personal data."))),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "10. GDPR Data Protection Rights"),
            React.createElement(RdiTypography, { variant: "body1" },
                "As a resident of EEA, your data is being protected by GDPR and we would like to make you sure that we are fully aware of all of your data protection rights. Every user is entitled to the following:",
                React.createElement("ul", null,
                    React.createElement("li", null, "The right to access \u2013 You have the right to request copies of your personal data. "),
                    React.createElement("li", null, "The right to rectification \u2013 You have the right to correct any information you believe is inaccurate. "),
                    React.createElement("li", null, "The right to erasure \u2013 You have the right to erase your personal data, under certain conditions."),
                    React.createElement("li", null, "The right to restrict processing \u2013 You have the right to restrict the processing of your personal data, under certain conditions."),
                    React.createElement("li", null,
                        "The right to object to processing \u2013 You have the right to object to",
                        React.createElement("ol", null,
                            React.createElement("li", null, "our processing of some of your information based on my legitimate interests and "),
                            React.createElement("li", null, "receiving marketing messages from us after providing your express consent to receive them. ")))),
                "In such cases, we will delete your personal information unless we have compelling and legitimate grounds to continue using that information or if it is needed for legal reasons. The right to data portability \u2013 You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions. If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "11. Data Retention"),
            React.createElement(RdiTypography, { variant: "body1" }, "When you place an order, we will maintain your Order Information for our records unless and until you ask us to delete this information."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "12. Online Privacy Rights"),
            React.createElement(RdiTypography, { variant: "body1" }, "Except GDPR and other regulations you may exercise some online privacy rights such as"),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "12.1. Email Marketing Opt-Out:"),
            React.createElement(RdiTypography, { variant: "body1" }, "All of our commercial email contains unsubscribe option. If you do not wish to receive any of our commercial marketing or email, you may unsubscribe anytime by following unsubscribe option given in the email. Please be noted it will take up to 15 days to opting out from our email marketing service and in this period, you may receive our promotional email. Opting out service does not include transaction alert or other updates from purchasing our service time to time."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "12.2. Mobile Device Permission"),
            React.createElement(RdiTypography, { variant: "body1" }, "Please be noted this option only includes our app \u201CMediSci CROS\u201D. Mobile devices need permission for specific types of device data such as microphone. You may not grant permission as you wish and not granting permission shall lose some of your services."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "12.3. Uninstall"),
            React.createElement(RdiTypography, { variant: "body1" }, "Please be noted this option only includes our app \u201CMediSci CROS\u201D. You may stop receiving everything from us if you unsubscribe, deactivate and uninstall our app. Please be noted mere uninstalling our app without unsubscribing shall not prevent us from taking your monthly subscription fees."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "13. Changes"),
            React.createElement(RdiTypography, { variant: "body1" }, "You are expected to recheck our privacy policy frequently. We may update this privacy policy from time to time in order to reflect. For example, changes to our practices or for other legal or regulatory reasons. We reserve the right to modify this privacy policy at any time. Changes will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances."),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: '12px', marginTop: '24px', width: '100%', textAlign: 'left' } }, "14. Contact Us"),
            React.createElement(RdiTypography, { variant: "body1" }, "For more information about our privacy policy, if you have questions, please contact us via email at suppor@medisci.io"))));
};
export default PrivacyPage;
