var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import RdiButton from "s~/components/forms/RdiButton";
import RdiInput from "s~/components/forms/RdiInput";
import CenteredContent from "s~/components/layout/content/CenteredContent";
import ModalContent from "s~/components/layout/content/ModalContent";
import PublicPageWrapper from "s~/components/layout/PublicPageWrapper";
import RdiTypography from "s~/components/layout/RdiTypography";
import RdiErrorBox from "s~/components/misc/RdiErrorBox";
import SuccessBox from "s~/components/misc/SuccessBox";
import { AccountRoutes } from "s~/components/routing/AccountRoutes";
import RdiLink from "s~/components/routing/RdiLink";
import { toLowercase } from "s~/utils/stringUtils";
var ResetPasswordComponent = /** @class */ (function (_super) {
    __extends(ResetPasswordComponent, _super);
    function ResetPasswordComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.email = "";
        _this.linkSent = false;
        _this.errorMessage = "";
        _this.handleEmailChange = function (e) {
            _this.email = e.value;
        };
        _this.onReset = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var resetPassword, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        resetPassword = this.props.resetPassword;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.errorMessage = "";
                        if (e) {
                            e.preventDefault();
                        }
                        return [4 /*yield*/, resetPassword(toLowercase(this.email))];
                    case 2:
                        _a.sent();
                        this.linkSent = true;
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.errorMessage = error_1.message || error_1.detail || error_1.toString();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ResetPasswordComponent.prototype.render = function () {
        return (React.createElement(PublicPageWrapper, { white: true },
            React.createElement(CenteredContent, null,
                !this.linkSent && (React.createElement(ModalContent, { style: { width: "480px" } },
                    React.createElement("form", { onSubmit: this.onReset, style: { flex: 1, justifyContent: "center" } },
                        React.createElement("div", null),
                        React.createElement("div", null,
                            React.createElement(RdiTypography, { variant: "h1", style: { paddingBottom: "25px", marginBottom: "0px" } }, "Reset your password"),
                            React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: "17px" } }, "Enter your email and receive a link for password reset."),
                            React.createElement(RdiInput, { autoFocus: true, type: "email", value: this.email, onChange: this.handleEmailChange, fullWidth: true, label: "Email", autoComplete: "email", style: { marginBottom: "15px" } }),
                            React.createElement(RdiButton, { style: {
                                    marginBottom: "15px",
                                    height: "57px"
                                }, fullWidth: true, submit: true }, "SEND RESET LINK")),
                        React.createElement(RdiErrorBox, null, this.errorMessage),
                        React.createElement(RdiLink, { to: AccountRoutes.LOGIN_ROUTE }, "Back to log in")))),
                this.linkSent && (React.createElement(SuccessBox, { title: "Reset link sent", text: "We sent a link to " + this.email + ". Please check your inbox and proceed from there.", to: AccountRoutes.LOGIN_ROUTE })))));
    };
    __decorate([
        observable
    ], ResetPasswordComponent.prototype, "email", void 0);
    __decorate([
        observable
    ], ResetPasswordComponent.prototype, "linkSent", void 0);
    __decorate([
        observable
    ], ResetPasswordComponent.prototype, "errorMessage", void 0);
    ResetPasswordComponent = __decorate([
        observer
    ], ResetPasswordComponent);
    return ResetPasswordComponent;
}(Component));
export default ResetPasswordComponent;
